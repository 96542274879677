// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".monitor-list_monitor-list_20k-y {\n  /* Width/height are set by the component, margin: auto centers in fullscreen */\n  margin: auto;\n  pointer-events: none;\n  overflow: hidden;\n}\n\n.monitor-list_monitor-list-scaler_143tA {\n  /* Scaling for monitors should happen from the top left */\n  transform-origin: left top;\n}\n\n::-ms-clear { display: none;\n}\n", ""]);
// Exports
exports.locals = {
	"monitor-list": "monitor-list_monitor-list_20k-y",
	"monitorList": "monitor-list_monitor-list_20k-y",
	"monitor-list-scaler": "monitor-list_monitor-list-scaler_143tA",
	"monitorListScaler": "monitor-list_monitor-list-scaler_143tA"
};
module.exports = exports;
