// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* #4C97FF */ /* #3373CC */ /* #855CD6 */ /* 35% transparent version of looks-tertiary */ /* 15% transparent version of looks-tertiary */ /* #714EB6 */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #0B8E69 */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ .share-button_share-button_Nxxf0 {\n  background: hsla(30, 100%, 55%, 1);\n} .share-button_share-button-is-shared_1tjKq {\n  background: hsla(0, 0%, 0%, 0.15);\n  cursor: default;\n}\n", ""]);
// Exports
exports.locals = {
	"share-button": "share-button_share-button_Nxxf0",
	"shareButton": "share-button_share-button_Nxxf0",
	"share-button-is-shared": "share-button_share-button-is-shared_1tjKq",
	"shareButtonIsShared": "share-button_share-button-is-shared_1tjKq"
};
module.exports = exports;
