// const mockObject = {
//     user: {
//         token: '',
//         scratch: {
//             user: {
//                 username: 'dongyu',
//                 nickname: '橙C',
//                 thumbnailUrl:
//                     'https://avatars.githubusercontent.com/u/11478092?v=4'
//             },
//             permissions: {
//                 educator: false,
//                 student: true
//             }
//         }
//     },
//     SETTINGS: {
//         SERVER_HOST: 'http://www.chengcode.com/api',
//         PROJECT_HOST: 'http://www.chengcode.com/api/projects',
//         ASSET_HOST: 'https://chengcode-assets.pages.dev'
//     }
// };

/**
 *
 * @returns {boolean} 是否是混合应用
 */
export const isHybrid = () => {
    if (window?.CHENG_CODE) {
        return true;
    }
    return false;
};

export const getHybridData = key => {
    if (isHybrid()) {
        return window.CHENG_CODE[key];
    }
    return false;
    // mockObject[key];
};


export const getHybridSettings = key => {
    const settings = getHybridData('SETTINGS');
    if (!settings){
        return false;
    }
    return settings[key];
};

/**
 *
 * @returns {object}|{boolean} 获取用户信息
 */
export const getHybridUser = () => {
    const userInfo = getHybridData('user');
    if (userInfo) {
        return userInfo;
    }
    return false;
};


export const getHybridSettingServerHost = () => getHybridSettings('SERVER_HOST');


export const getHybridSettingAssetHost = () => getHybridSettings('ASSET_HOST');

export const getHybridSettingProjectHost = () => getHybridSettings('PROJECT_HOST');
