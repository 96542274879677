import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import ReactModal from 'react-modal';
import styles from './login-modal.css';
import Input from '../forms/input';
import ButtonComponent from '../button/button';
import {login, XBlockError} from '../../lib/http';
import {setToken} from '../../lib/auth';

const LoginModal = ({
    isOpen,
    onClose,
    onLoginSuccess
}) => {
    const [feedback, setFeedback] = useState(null);
    const [loginData, setLoginData] = useState({
        username: '',
        password: ''
    });

    const handleChangeUsername = e => {
        setLoginData({
            ...loginData,
            username: e.target.value
        });
    };
    const handleChangePassword = e => {
        setLoginData({
            ...loginData,
            password: e.target.value
        });
    };

    const handleLogin = async () => {
        if (!loginData.username || !loginData.password) {
            setFeedback({
                state: 'error',
                message: '请填写账号和密码'
            });
            return;
        }
        setFeedback({
            state: 'loading'
        });
        try {
    
            const data = await login(loginData.username, loginData.password);
            setFeedback({
                state: 'success'
            });
            setToken(data.token);
            onLoginSuccess(data.scratch);
            onClose();
            setLoginData({
                username: '',
                password: ''
            });
        } catch (e){
            if (e instanceof XBlockError){
                setFeedback({
                    state: 'error',
                    message: e.message
                });
                return;
            }
            setFeedback({
                state: 'error',
                message: '登录失败，请稍后重试'
            });
        }
        
    };

    return (<ReactModal
        isOpen={isOpen}
        className={styles.modalContent}
        overlayClassName={styles.modalOverlay}
        onRequestClose={onClose}
    >
        <div className={styles.formContainer}>
            <div className={styles.title}>{'登录'}</div>

            {feedback && feedback.state === 'error' && <div className={styles.error}>{feedback.message}</div>}
            <Input
                placeholder="账号"
                className={styles.loginInput}
                value={loginData.username}
                // eslint-disable-next-line react/jsx-no-bind
                onChange={handleChangeUsername}
            />
            <Input
                type="password"
                placeholder="密码"
                className={styles.loginInput}
                value={loginData.password}
                // eslint-disable-next-line react/jsx-no-bind
                onChange={handleChangePassword}
            />
            <ButtonComponent
                className={styles.loginBtn}
                disabled={feedback && feedback.state === 'loading'}
                // eslint-disable-next-line react/jsx-no-bind
                onClick={handleLogin}
            >{'登录'}</ButtonComponent>
       
        </div>
    
    </ReactModal>);
};

LoginModal.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onLoginSuccess: PropTypes.func
};

export default LoginModal;
