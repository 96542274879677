// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes mic-indicator_popIn_2A2Un {\n  from {\n    transform: scale(0.5)\n  }\n\n  to {\n    transform: scale(1)\n  }\n}\n\n.mic-indicator_mic-img_Su3yZ {\n  margin: 10px;\n  transform-origin: center;\n  animation: mic-indicator_popIn_2A2Un 0.1s ease-in-out;\n}\n", ""]);
// Exports
exports.locals = {
	"mic-img": "mic-indicator_mic-img_Su3yZ",
	"micImg": "mic-indicator_mic-img_Su3yZ",
	"popIn": "mic-indicator_popIn_2A2Un"
};
module.exports = exports;
