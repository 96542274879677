import axios from 'axios';
import {getToken} from './auth';
import {defaultProjectId} from '../reducers/project-state';
import {getHybridSettingServerHost} from './hybrid';
const baseURL = getHybridSettingServerHost() || process.env.SERVER_HOST || 'http://localhost:3000';
const http = axios.create({
    baseURL
});

export class XBlockError extends Error {
    constructor (message, code = 1000) {
        super(message);
        this.code = code;
    }

}

http.interceptors.request.use(config => {
    const token = getToken();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

http.interceptors.response.use(response => {
    if (response.data.error){
        const {code, message} = response.data.error;
        throw new XBlockError(message, code);
    }
    return response;
}, error => {
    if (error.response.status === 401) {
        localStorage.removeItem('token');
    }
    return Promise.reject(error);
});

export const login = async (username, password) => {
    const resp = await http.post('/user/login', {username, password});
    return resp.data;
};

export const getUserSession = async () => {
    const resp = await http.get(`/user/session`);
    return resp.data;
};


export const uploadScratchAssets = async (projectId, filename, blobData) => {
    const formData = new FormData();
    formData.append('file', blobData, filename);
    formData.append('projectId', projectId);
    const resp = await http.post(`/studio/project/asset/${projectId}`, formData);
    return resp.data;
};
export const uploadProjectCover = async (projectId, blobData) => {
    const formData = new FormData();
    formData.append('file', blobData);
    formData.append('projectId', projectId);
    const resp = await http.post(`/studio/project/cover/${projectId}`, formData);
    return resp.data;
};

// 保存项目代码
export const saveProject = async (projectId, code, queryString) => {
    const creatingProject = projectId === null || typeof projectId === 'undefined' || projectId === defaultProjectId;
    let resp;
    if (creatingProject){
        resp = await http.post(`/studio/project/code/?${queryString}`, {code: code});
    } else {
        resp = await http.put(`/studio/project/code/${projectId}?${queryString}`, {code: code});
    }
    return resp.data;
};

// 获取项目代码
export const getProject = async projectId => {
    const resp = await http.get(`/studio/project/code/${projectId}`);
    return resp.data;
};

export default http;
