const LOGIN = 'scratch-gui/session/LOGIN';
const LOGOUT = 'scratch-gui/session/LOGOUT';

export const initialSessionState = {
    session: {
        user: null
    }
};
// 是否登录
export const isLogin = state => {
    const {session} = state;
    return !!(session.session.user && session.session.user.username);
};

export const login = function (session) {
    return {
        type: LOGIN,
        session: session
    };
};

export const logout = function () {
    return {
        type: LOGOUT
    };
};


const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialSessionState;
    switch (action.type) {
    case LOGIN:
        return Object.assign({}, state, {
            session: action.session
        });
    case LOGOUT:
        return Object.assign({}, state, {
            session: {
                user: null
            }
        });
    default:
        return state;
    }
};

export {reducer as default, initialSessionState as sessionStateInitialState};
