// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* make sure to keep these in sync with other constants,\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */\n\n/* layout contants from `layout-constants.js` */\n\n.button_outlined-button_1bS__ {\n  cursor: pointer;\n  border-radius: calc(0.5rem / 2);\n  font-weight: bold;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  padding-left: .75rem;\n  padding-right: .75rem;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n      -ms-user-select: none;\n          user-select: none;\n}\n\n.button_icon_77d8G {\n  height: 1.5rem;\n}\n\n[dir=\"ltr\"] .button_icon_77d8G {\n  margin-right: .5rem;\n}\n\n[dir=\"rtl\"] .button_icon_77d8G {\n  margin-left: .5rem;\n}\n\n.button_content_3jdgj {\n  white-space: nowrap;\n}\n", ""]);
// Exports
exports.locals = {
	"outlined-button": "button_outlined-button_1bS__",
	"outlinedButton": "button_outlined-button_1bS__",
	"icon": "button_icon_77d8G",
	"content": "button_content_3jdgj"
};
module.exports = exports;
