// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* #4C97FF */ /* #3373CC */ /* #855CD6 */ /* 35% transparent version of looks-tertiary */ /* 15% transparent version of looks-tertiary */ /* #714EB6 */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #0B8E69 */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ .icon-button_container_278u5 {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  cursor: pointer;\n  font-size: 0.75rem;\n  font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n  color: hsla(260, 60%, 60%, 1);\n  border-radius: 0.5rem;\n} .icon-button_container_278u5 + .icon-button_container_278u5 {\n  margin-top: 1.25rem;\n} .icon-button_title_36ChS {\n  margin-top: 0.5rem;\n  text-align: center;\n} .icon-button_disabled_2HD_J {\n  opacity: 0.5;\n  pointer-events: none;\n} .icon-button_container_278u5:active {\n  background-color: hsla(260, 60%, 60%, 0.15);\n}\n", ""]);
// Exports
exports.locals = {
	"container": "icon-button_container_278u5",
	"title": "icon-button_title_36ChS",
	"disabled": "icon-button_disabled_2HD_J"
};
module.exports = exports;
